import { createSlice } from "@reduxjs/toolkit";

const selectedDepattIdSlice = createSlice({
  name: "depattId",
  initialState: {
    depttId: "",
  },
  reducers: {
    setDepttId(state, action) {
      state.depttId = action.payload;
    },
  },
});

export const { setDepttId } = selectedDepattIdSlice.actions;
export default selectedDepattIdSlice.reducer;

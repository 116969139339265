import React, { Fragment, useEffect, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  ChevronDownIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearUser } from "../redux/features/userSlice";
import images from "../service/imageImporter";
import { clearAllottedPrograms } from "../redux/features/myProgramsSlice";

const navigation = [
  { name: "Home", href: "/", current: false },
  { name: "About", href: "#", current: false },
  { name: "Projects", href: "#", current: false },
  { name: "Contact Us", href: "#", current: false },
];
const navigationLearner = [
  {
    name: "Offered Programs",
    href: "/technohub/learner/all-programs",
    current: false,
  },
  {
    name: "My Programs",
    href: "/technohub/learner/my-programs",
    current: false,
  },
];

const navigationEnabler = [
  { name: "Home", href: "/technohub/enabler/dashboard", current: false },
  {
    name: "Sponsorship Applicants",
    href: "/technohub/enabler/sponsors",
    current: false,
  },
  {
    name: "Beneficiaries",
    href: "/technohub/enabler/sponsored-beneficiaries",
    current: false,
  },
  { name: "Post Job", href: "/technohub/enabler/post-job", current: false },
  {
    name: "Learner Available",
    href: "/technohub/enabler/learner-available",
    current: false,
  },
];

const navigationAdmin = [
  { name: "Home", href: "/admin/dashboard/home", current: false },
  { name: "About", href: "#", current: false },
  // { name: "Posted Job", href: "#", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Navbar() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const allotted = useSelector((state) => state.myPrograms.allottedPrograms);
  const [checkAllot, setCheckAllot] = useState(allotted);
  const logOutHandler = () => {
    localStorage.removeItem("lgs_token");
    localStorage.removeItem("desired_courses");
    localStorage.removeItem("user");
    localStorage.removeItem("deptt_id");
    dispatch(clearAllottedPrograms());
    dispatch(clearUser());
  };

  useEffect(() => {
    if (allotted && allotted.length > 0) {
      setCheckAllot(true);
    } else {
      setCheckAllot(false);
    }
  }, [allotted]);

  return (
    <>
      <Disclosure
        as="nav"
        className="z-50 bg-gradient-to-r from-blue-400 from-10% via-sky-400 via-30%
      to-emerald-400 to-70% ..."
        // className="bg-blue-900"
      >
        {({ open }) => (
          <>
            <div className="z-50 mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
              <div className="relative flex h-16 items-center justify-between">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-800 hover:bg-blue-800 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-700">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                  <div className="flex flex-shrink-0 items-center max-[640px]:hidden">
                    {user.isAuth && user.userData.role_id === 1 ? (
                      <Link to="/admin/dashboard/home">
                        <img
                          className="h-12 w-auto"
                          src={images["Technohub.jpeg"]}
                          alt="Your Company"
                        />
                      </Link>
                    ) : user.userData.role_id === 2 ? (
                      <Link to="/technohub/enabler/dashboard">
                        <img
                          className="h-12 w-auto"
                          src={images["Technohub.jpeg"]}
                          alt="Your Company"
                        />
                      </Link>
                    ) : user.userData.role_id === 3 ? (
                      <Link to="/technohub/learner/all-programs">
                        <img
                          className="h-12 w-auto"
                          src={images["Technohub.jpeg"]}
                          alt="Your Company"
                        />
                      </Link>
                    ) : (
                      <Link to="/">
                        <img
                          className="h-12 w-auto"
                          src={images["Technohub.jpeg"]}
                          alt="Your Company"
                        />
                      </Link>
                    )}
                  </div>

                  <div className="hidden sm:ml-6 sm:block">
                    <div className="flex space-x-4">
                      {user.isAuth && user.userData.role_id == 1
                        ? navigationAdmin.map((item) => (
                            <Link
                              key={item.name}
                              to={item.href}
                              className={classNames(
                                item.current
                                  ? "bg-green-300 text-white"
                                  : "text-white hover:bg-blue-600 hover:text-white",
                                "rounded-md px-3 py-2 mt-2 text-sm font-medium"
                              )}
                              aria-current={item.current ? "page" : undefined}
                            >
                              {item.name}
                            </Link>
                          ))
                        : user.isAuth && user.userData.role_id == 3
                        ? navigationLearner.map((item) => (
                            <Link
                              key={item.name}
                              to={item.href}
                              className={classNames(
                                item.current
                                  ? "bg-green-300 text-white"
                                  : "text-white hover:bg-blue-600 hover:text-white",
                                "rounded-md px-3 py-2 mt-2 text-sm font-medium"
                              )}
                              aria-current={item.current ? "page" : undefined}
                            >
                              {item.name}
                            </Link>
                          ))
                        : user.isAuth && user.userData.role_id == 2
                        ? navigationEnabler.map((item) => (
                            <Link
                              key={item.name}
                              to={item.href}
                              className={classNames(
                                item.current
                                  ? "bg-green-300 text-white"
                                  : "text-white hover:bg-blue-600 hover:text-white",
                                "rounded-md px-3 py-2 mt-2 text-sm font-medium"
                              )}
                              aria-current={item.current ? "page" : undefined}
                            >
                              {item.name}
                            </Link>
                          ))
                        : navigation.map((item) => (
                            <Link
                              key={item.name}
                              to={item.href}
                              className={classNames(
                                item.current
                                  ? "bg-green-300 text-white"
                                  : "text-white hover:bg-blue-600 hover:text-white",
                                "rounded-md px-3 py-2 mt-2 text-sm font-medium"
                              )}
                              aria-current={item.current ? "page" : undefined}
                            >
                              {item.name}
                            </Link>
                          ))}
                    </div>
                  </div>
                </div>
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  {user.isAuth ? null : (
                    <Link to="/admin/login">
                      <button
                        type="button"
                        className="relative rounded-md h-10 bg-blue-900 p-1 px-3 text-white hover:border-2 hover:bg-inherit"
                      >
                        Admin Login
                      </button>
                    </Link>
                  )}

                  {user.isAuth ? (
                    <Menu as="div" className="relative ml-3">
                      {user.isAuth && user.userData.role_id == 3 ? (
                        <div>
                          <div className="relative flex rounded-full float-left clear-both mt-1 mx-4  text-sm focus:outline-none max-[280px]:hidden">
                            <span className="absolute -inset-1.5" />
                            <span className="sr-only">Open user menu</span>

                            <div className="bg-gray-200 rounded-lg border-none p-2">
                              <h2>Welcome, {user.userData.user_name} </h2>
                            </div>
                          </div>
                          <Menu.Button className="relative flex rounded-full ">
                            <span className="absolute -inset-1.5" />
                            <span className="sr-only">Open user menu</span>

                            <div className="bg-gray-200 rounded-full border-none shadow-lg  ">
                              {user?.userData?.participant_photo ? (
                                <img
                                  src={user?.userData?.participant_photo}
                                  alt="Profile"
                                  className="rounded-full h-10 w-10 hover:scale-110 transform ease-in-out shadow-lg"
                                />
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth="1.5"
                                  stroke="#333"
                                  className="w-10 h-10"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                                  />
                                </svg>
                              )}
                            </div>
                          </Menu.Button>
                        </div>
                      ) : (
                        <div>
                          <Menu.Button className="relative flex rounded-full  text-sm focus:outline-none">
                            <span className="absolute -inset-1.5" />
                            <span className="sr-only">Open user menu</span>

                            <div className="bg-gray-200 rounded-lg border-none p-2">
                              <h2>
                                Welcome, {user.userData.user_name}
                                <ChevronDownIcon className="w-4 h-4 ml-2 mt-[3px] float-right" />
                              </h2>
                            </div>
                          </Menu.Button>
                        </div>
                      )}
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {user.isAuth && user.userData.role_id == 3 && (
                            <Menu.Item>
                              {({ active }) => (
                                <>
                                  {user.isAuth &&
                                  user.userData.role_id === 3 ? (
                                    <Link
                                      to="/technohub/learner/profile"
                                      className={classNames(
                                        active ? "bg-gray-100" : "",
                                        "block px-4 py-2 text-sm text-gray-700"
                                      )}
                                    >
                                      My Profile
                                    </Link>
                                  ) : (
                                    <span
                                      className={classNames(
                                        active ? "bg-gray-100" : "",
                                        "block px-4 py-2 text-sm text-gray-700"
                                      )}
                                    >
                                      {/* You can add some text or content for roles 1 and 2 here */}
                                    </span>
                                  )}
                                </>
                              )}
                            </Menu.Item>
                          )}
                          {checkAllot &&
                            user.isAuth &&
                            user.userData.role_id === 3 && (
                              <Menu.Item>
                                {({ active }) =>
                                  allotted.length > 0 && (
                                    <Link
                                      to="/technohub/learner/apply"
                                      className={classNames(
                                        active ? "bg-gray-100" : "",
                                        "block px-4 py-2 text-sm text-gray-700"
                                      )}
                                    >
                                      Apply for Sponsorship
                                    </Link>
                                  )
                                }
                              </Menu.Item>
                            )}
                          {user.isAuth && (
                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  to={
                                    user.userData.role_id == 1
                                      ? "/admin/changepwd"
                                      : user.userData.role_id == 2
                                      ? "/technohub/enabler/changepwd"
                                      : "/technohub/learner/changepwd"
                                  }
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700"
                                  )}
                                >
                                  Change Password
                                </Link>
                              )}
                            </Menu.Item>
                          )}

                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                to="/"
                                onClick={logOutHandler}
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                              >
                                Log out
                              </Link>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  ) : null}
                </div>
              </div>
            </div>
            <Disclosure.Panel className="sm:hidden ">
              <div className="space-y-1 px-2 pb-3 pt-2">
                {user.isAuth && user.userData.role_id == 1
                  ? navigationAdmin.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          item.current
                            ? "bg-green-300 text-white"
                            : "text-gray-800  hover:text-blue ",
                          "block rounded-md px-3 py-2 text-sm font-medium"
                        )}
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))
                  : user.isAuth && user.userData.role_id == 3
                  ? navigationLearner.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          item.current
                            ? "bg-green-300 text-white"
                            : "text-gray-800  hover:text-blue",
                          " block  rounded-md px-3 py-2 text-sm font-medium"
                        )}
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))
                  : user.isAuth && user.userData.role_id == 2
                  ? navigationEnabler.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          item.current
                            ? "bg-green-300 text-white"
                            : "text-gray-800  hover:text-blue",
                          " block rounded-md px-3 py-2 text-sm font-medium"
                        )}
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))
                  : navigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          item.current
                            ? "bg-green-300 text-white"
                            : "text-gray-800  hover:text-blue",
                          " block rounded-md px-3 py-2 text-sm font-medium"
                        )}
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
}

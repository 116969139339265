import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { clearUser, setUser } from "../redux/features/userSlice";
import { BeatLoader } from "react-spinners";

const LearnerTokenMiddleware = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [isLearner, setIsLearner] = useState(false);
  const [redirectPath, setRedirectPath] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem("lgs_token");
    if (!token) {
      setIsLoading(false);
      setIsTokenValid(false);
      localStorage.removeItem("lgs_token");
      dispatch(clearUser());
    } else {
      fetch("https://api.lavanyagurukul.com/api/auth/token-info", {
      // fetch("http://localhost:5000/api/auth/token-info", {
      //  fetch("https://apilavanyagurukuldev.q4hosting.com/api/auth/token-info", {
         method: "POST",
         headers: {
           "Content-Type": "application/json",
           authorization: `Bearer ${token}`,
         },
       })
         .then((response) => {
           return response.json();
         })
         .then((data) => {
           if (!data.ok) {
             setIsLoading(false);
             setIsTokenValid(false);
             dispatch(clearUser());
             localStorage.removeItem("lgs_token");
           } else {
             setIsLoading(false);
             const { tokenData } = data;
             const userInfo = tokenData;
             if (userInfo.role_id == 3) {
               setIsTokenValid(true);
               // localStorage.setItem("user", JSON.stringify(userInfo));
               dispatch(setUser(userInfo));
               setIsLearner(true);
               let desired_courses = localStorage.getItem("desired_courses");
               if (
                 !userInfo.participant_id_type ||
                 !userInfo.participant_identity ||
                 !userInfo.participant_gender ||
                 !userInfo.user_address
               ) {
                 setRedirectPath("/technohub/learner/profile");
               } else if (desired_courses == "null") {
                 setRedirectPath("/technohub/learner/allprogram");
                 return false;
               } else {
                 setRedirectPath(null);
               }
             }
           }
         })
         .catch((error) => {
           setIsLoading(false);
           setIsTokenValid(false);
           localStorage.removeItem("lgs_token");
           dispatch(clearUser());
         });
    }
  }, []);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center mt-40 mb-44">
        <BeatLoader color="#34b69c" loading={true} size={15} />
        <br />
      </div>
    );
  } else if (!isTokenValid || !isLearner) {
    return <Navigate to="/" />;
  } else if (redirectPath) {
    return <Navigate to={redirectPath} />;
  } else {
    return <React.Fragment>{children}</React.Fragment>;
  }
};

export default LearnerTokenMiddleware;

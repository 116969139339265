import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import EnablerLoginMiddleware from "../middleware/enabler-login-middleware";
import EnablerTokenMiddleware from "../middleware/enabler-token-middleware";
import OfferedPrograms from "../component/Enabler/OfferedPrograms";
import EnablerSponsor from "../component/Enabler/EnablerSponsor";
import EnablerJobComponent from "../component/Enabler/EnablerJobComponent";
import PostJob from "../component/Enabler/PostJob";
import LearnerAvailable from "../component/Enabler/Learner-Available";

const EnablerChangePwd = lazy(() =>
  import("../component/Enabler/EnablerChangePwd")
);
const EnablerDashboard = lazy(() =>
  import("../component/Enabler/EnablerDashboard")
);
const EnablerLogin = lazy(() => import("../component/Enabler/EnablerLogin"));
const EnablerSignUp = lazy(() => import("../component/Enabler/EnablerSignUp"));
const PaymentStatus = lazy(() => import("../component/Enabler/PaymentStatus"));
const SponsoredBeneficiaries = lazy(() =>
  import("../component/Enabler/SponsoredBeneficiaries")
);

const Enabler = () => {
  return (
    <>
      <Routes>
        <Route
          path="signup"
          element={
            <EnablerLoginMiddleware>
              <EnablerSignUp />
            </EnablerLoginMiddleware>
          }
        />
        <Route
          path="changepwd"
          element={
            <EnablerTokenMiddleware>
              <EnablerChangePwd />
            </EnablerTokenMiddleware>
          }
        />
        <Route
          path="login"
          element={
            <EnablerLoginMiddleware>
              <EnablerLogin />{" "}
            </EnablerLoginMiddleware>
          }
        />
        ;
        <Route
          path="sponsors"
          element={
            <EnablerTokenMiddleware>
              <EnablerSponsor />{" "}
            </EnablerTokenMiddleware>
          }
        />
        <Route
          path="dashboard"
          element={
            <EnablerTokenMiddleware>
              <EnablerDashboard />{" "}
            </EnablerTokenMiddleware>
          }
        />
        <Route
          path="payment-status"
          element={
            <EnablerTokenMiddleware>
              <PaymentStatus />
            </EnablerTokenMiddleware>
          }
        />
        <Route
          path="programs"
          element={
            <EnablerTokenMiddleware>
              <OfferedPrograms />{" "}
            </EnablerTokenMiddleware>
          }
        />
        <Route
          path="post-job"
          element={
            <EnablerTokenMiddleware>
              <PostJob />{" "}
            </EnablerTokenMiddleware>
          }
        />
        <Route
          path="learner-available"
          element={
            <EnablerTokenMiddleware>
              <LearnerAvailable />{" "}
            </EnablerTokenMiddleware>
          }
        />
        <Route
          path="sponsored-beneficiaries"
          element={
            <EnablerTokenMiddleware>
              <SponsoredBeneficiaries />{" "}
            </EnablerTokenMiddleware>
          }
        />
        <Route
          path="update-job/:id"
          element={
            <EnablerTokenMiddleware>
              <EnablerJobComponent />{" "}
            </EnablerTokenMiddleware>
          }
        />
      </Routes>
    </>
  );
};

export default Enabler;

import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Link, useNavigate } from "react-router-dom";
import EnablerModal from "../Enabler/EnablerModal";

export default function TechnoHub({ open, setOpen, learnerDesc, enablerDesc }) {
  const [openModal, setOpenModal] = useState(false);

  const cancelButtonRef = useRef(null);
  const navigate = useNavigate();

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className=" px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <Link to="/">
                      <button
                        type="button"
                        className="mt-2 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-200 sm:mt-0 sm:w-auto max-[640px]:w-[10%] max-[460px]:w-[17%] max-[270px]:w-[20%]"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-5 h-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </Link>
                  </div>
                  <h1 className="text-center font-bold text-2xl text-blue-700">
                    Choose A Role
                  </h1>
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className=" text-center">
                      <div className="grid grid-cols-2 gap-4 max-[640px]:grid-cols-1">
                        <div>
                          <p className="text-start border-2 rounded-md p-4 mb-4">
                            {learnerDesc}
                          </p>
                          <div>
                            <Link to="/technohub/learner/learner-auth">
                              <button
                                className="bg-gradient-to-r from-blue-400 to-blue-700 
               hover:from-blue-400 hover:to-green-400 mt-4 w-40 rounded-md  px-3 py-2 text-center text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
                              >
                                Learner
                              </button>
                            </Link>
                          </div>
                        </div>
                        <div className="">
                          <p className="text-start border-2 rounded-md p-4 mb-4">
                            {enablerDesc}
                          </p>
                          <div className="">
                            <button
                              className="bg-gradient-to-r from-blue-400 to-blue-700 
               hover:from-blue-400 hover:to-green-400 mt-4 w-40 rounded-md  px-3 py-2 text-center text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
                              onClick={() =>
                                navigate("/technohub/enabler/login")
                              }
                            >
                              Enabler
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <EnablerModal openModal={openModal} setOpenModal={setOpenModal} />
    </>
  );
}

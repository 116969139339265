import axios from "axios";
export const fetch = async (
  endPoint = "",
  method = "get",
  data = null,
  headers = {}
) => {
  const instance = axios.create({
    baseURL: "https://api.lavanyagurukul.com/api",
    // baseURL: "http://localhost:5000/api",
//  baseURL: "https://apilavanyagurukuldev.q4hosting.com/api",
  });
  return await instance({
    url: endPoint,
    method,
    data,
    headers,
  });
};

 export const formatText = (text) => {
    const words = text.split("_");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    const formattedText = capitalizedWords.join(" ");
    return formattedText;
 };
  
 export const formatArray = (arr) => {
   const words = arr && arr.join(",");
   return words;
 };

 export const getQualificationInfo = (learner) => {
   const { participant_qualification } = learner;
   return (
     participant_qualification &&
     `${participant_qualification[0].course_name} | ${participant_qualification[0].degree}`
   );
 };
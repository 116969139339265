import React, { useState, useEffect } from "react";
import { feeReg } from "../../validations/validation";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import TextArea from "antd/es/input/TextArea";
import images from "../../service/imageImporter";
import { fetch } from "../../service/utils";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useInput } from "../../hook/input-hook";
import { theme } from "antd";
import { BeatLoader } from "react-spinners";
import toast from "react-hot-toast";

const isOccupation = (value) => {
  if (value.trim() === "") {
    return {
      validated: false,
      message: "Please Enter Your Father's Occupation.",
    };
  } else {
    return { validated: true, message: "" };
  }
};

const isIncome = (value, isTouchIncome) => {
  if (value === "" && isTouchIncome) {
    return {
      validated: false,
      message: "Please Enter Your Annual Family Income.",
    };
  } else if (!feeReg.test(value)) {
    return {
      validated: false,
      message: "Please Enter A Valid Amount.",
    };
  } else {
    return { validated: true, message: "" };
  }
};
function SponsorshipForm() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [networkError, setNetworkError] = useState(false);
  const [occupationTouch, setOccupationTouch] = useState("");
  const [isIncomeTouchblur, setIncomeTouchblur] = useState("");
  const [openAlert, setCloseAlert] = useState(false);

  const {
    hasError: hasErrorOccupation,
    enterValue: enterValueOccupation,
    message: occupationMessage,
    onChangeHandler: onChangeHandlerOccupation,
    isTouch: isTouchOccupation,
    onBlurHandler: onBlurHandlerOccupation,
    setDirect:setOccupation,
  } = useInput(isOccupation, setNetworkError, setOccupationTouch);

  const {
    hasError: hasErrorIncome,
    enterValue: enterValueIncome,
    onChangeHandler: onChangeHandlerIncome,
    message: IncomeMessage,
    isTouch: isTouchIncome,
    onBlurHandler: onBlurHandlerIncome,
    setDirect:setIncome,
  } = useInput(isIncome, setNetworkError, setIncomeTouchblur);

  useEffect(() => {
    setSubError("");
    setIncomeTouchblur("");
    setOccupationTouch("");
  }, [enterValueIncome, enterValueOccupation]);

  const learnerUser = useSelector((state) => state.user.userData);

  const [display_name, setDisplayName] = useState(learnerUser.user_name);

  const [userAddress, setUserAddress] = useState(
    learnerUser.user_address == null ? "" : learnerUser.user_address
  );

  const [subError, setSubError] = useState("");

  // *phone start
  const [contMobile, setContMobile] = useState(learnerUser.user_mobile_no);
  const [nationalNumber, setNationalNumber] = useState(
    learnerUser.user_mobile_no
  );
  const [mobileError, setMobileError] = useState("");

  const handlePhoneChange = (value, country, e, formattedValue) => {
    setContMobile(value);
    const phoneNumber = parsePhoneNumberFromString(formattedValue, country);
    phoneNumber && setNationalNumber(phoneNumber.nationalNumber);
    if (phoneNumber && phoneNumber.isValid()) {
      setMobileError("");
    } else {
      setMobileError("Invalid phone number!");
    }
  };
  // *phone end

// const
  const getExistSponsor = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("lgs_token");
      const headers = { Authorization: `Bearer ${token}` };
      const response = await fetch(
        "/learner/sponsorship-details",
        "get",
        null,
        headers
      );
      setIsLoading(false);
      if (response.data.ok === true) {
        setOccupation(response.data.details.father_occupation);
        setIncome(response.data.details.family_income);
      } else {
        // setApplied(true);
      }
    } catch (err) {
      setIsLoading(false);
      if (err.response && err.response.data && err.response.data.message) {
        // setNetworkError(err.response.data.message);
      } else {
        // setNetworkError("Something Went Wrong. Please Try Again Later.");
      }
    }
  };

  useEffect(() => {
    getExistSponsor();
  }, []);

  const submitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setNetworkError("");
    if (hasErrorOccupation === true || hasErrorIncome === true) {
      setSubError("fields");
      setIsLoading(false);
      return false;
    }
    // else if (!isTouchOccupation || !isTouchIncome) {
    //   if (!isTouchOccupation) {
    //     setOccupationTouch("Please Enter Your Father's Occupation");
    //   }
    //   if (!isTouchIncome) {
    //     setIncomeTouchblur("Please Enter Your Family's Annual Income.");
    //   }
    //   setSubError("fields");
    //   setIsLoading(false);
    //   return false;
    // }
    try {
      const token = localStorage.getItem("lgs_token");
      const response = await fetch(
        "/learner/apply-sponsorship",
        "post",
        {
          learner_name: display_name,
          learner_contact: contMobile,
          father_occupation: enterValueOccupation,
          family_income: enterValueIncome,
          learner_address: userAddress,
        },
        {
          Authorization: `Bearer ${token}`,
        }
      );
      if (response.data.ok === false) {
        setIsLoading(false);
        setNetworkError(response.data.message);
        toast.error(response.data.message);
        return false;
      } else {
        setNetworkError("");
        setIsLoading(false);
        navigate("/technohub/learner/my-programs");
        toast.success(response.data.message);
      }
    } catch (err) {
      setIsLoading(false);
      if (err.response && err.response.data && err.response.data.message) {
        setNetworkError(err.response.data.message);
        toast.error(err.response.data.message);
      } else {
        setNetworkError("Something Went Wrong. Please Try Again Later.");
        toast.error("Something Went Wrong. Please Try Again Later.");
      }
    }
  };
  const {
    token: { colorBgContainer },
  } = theme.useToken();


  return (
    <>
      <div
        style={{
          padding: 6,
          minHeight: 360,
          background: colorBgContainer,
        }}
      >
        {isLoading && (
          <div className="flex justify-center h-screen items-center">
            <BeatLoader color="#34b69c" loading={true} size={15} />
          </div>
        )}

        {networkError && isLoading === false && (
          <div className="text-center mt-8">
            <strong className="font-bold text-red-600 italic align-center">
              <div
                className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                role="alert"
              >
                <strong className="font-bold">**{networkError}**</strong>

                <Link
                  to="/technohub/learner/my-programs"
                  onClick={() => setCloseAlert(false)}
                >
                  <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                    <svg
                      className="fill-current h-6 w-6 text-red-500"
                      role="button"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <title>Close</title>
                      <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                    </svg>
                  </span>
                </Link>
              </div>
            </strong>
          </div>
        )}
        {!isLoading && !networkError && (
          <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
              <img
                className="mx-auto h-36 w-auto"
                src={images[`Technohub.jpeg`]}
                alt="Your Company"
              />
              <h2 className="mx-2 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                Sponsorship Application
              </h2>
            </div>

            <div className="mt-6 sm:mx-auto md:w-[55%]">
              <form className="space-y-6 " onSubmit={submitHandler}>
                <div className="grid grid-cols-2 gap-x-2 gap-y-2 max-[640px]:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:gap-x-8 ">
                  <div>
                    <label className="block pl-2 text-sm font-bold leading-6 text-gray-900">
                      Name <span className="text-red-600 font-bold">*</span>
                    </label>

                    <div className="mt-2">
                      <input
                        id="Name"
                        name="Name"
                        type="text"
                        autoComplete="Name"
                        defaultValue={display_name}
                        onChange={(e) => setDisplayName(e.target.value)}
                        disabled
                        className="cursor-not-allowed block w-full rounded-md border-0 py-1.5 px-2 font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                    {subError === "name" && (
                      <strong className="text-red-500 text-sm italic text-center">
                        *Please Enter Your Name.*
                      </strong>
                    )}
                  </div>
                  <div>
                    <label className="block pl-2 text-sm font-bold leading-6 text-gray-900">
                      Mobile <span className="text-red-600 font-bold">*</span>
                    </label>
                    <div className="mt-2">
                      <PhoneInput
                        inputStyle={{ width: "100%" }}
                        countryCodeEditable={false}
                        value={contMobile}
                        inputProps={{
                          name: "phone",
                          required: true,
                        }}
                        country={"in"}
                        onChange={handlePhoneChange}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-x-2 gap-y-2 max-[640px]:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:gap-x-8 ">
                  <div>
                    <div className="flex items-center justify-between">
                      <label
                        htmlFor="father_occupation"
                        className="block text-sm font-bold leading-6 text-gray-900"
                      >
                        Father's Occupation{" "}
                        <span className="text-red-600 font-bold">*</span>
                      </label>
                    </div>
                    <div className="mt-2 flex">
                      <input
                        id="father_occupation"
                        name="father_occupation"
                        type="text"
                        autoComplete="occupation"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-3 font-medium"
                        onChange={onChangeHandlerOccupation}
                        defaultValue={enterValueOccupation}
                        onBlur={() => {
                          onBlurHandlerOccupation();
                          setOccupationTouch("");
                        }}
                      />
                    </div>
                    {hasErrorOccupation && (
                      <strong className="text-red-500 text-sm italic">
                        {occupationMessage}
                      </strong>
                    )}
                    {occupationTouch && (
                      <strong className="text-red-500 text-sm italic">
                        {occupationTouch}
                      </strong>
                    )}
                  </div>
                  <div>
                    <div className="flex items-center justify-between">
                      <label
                        htmlFor="family-income"
                        className="block text-sm font-bold leading-6 text-gray-900"
                      >
                       Annual Family Income
                      </label>
                    </div>
                    <div className="mt-2 flex">
                      <input
                        id="family-income"
                        name="family-income"
                        type="text"
                        autoComplete="family-income"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-3 font-medium"
                        onChange={onChangeHandlerIncome}
                        defaultValue={enterValueIncome}
                        onBlur={() => {
                          onBlurHandlerIncome();
                          setIncomeTouchblur("");
                        }}
                      />
                    </div>
                    {hasErrorIncome && (
                      <strong className="text-red-500 text-sm italic">
                        {IncomeMessage}
                      </strong>
                    )}
                    {isIncomeTouchblur && (
                      <strong className="text-red-500 text-sm italic">
                        {isIncomeTouchblur}
                      </strong>
                    )}
                  </div>
                </div>

                <div className="grid grid-cols-1 ">
                  <label className="block pl-2 text-sm font-bold leading-6 text-gray-900">
                    Address <span className="text-red-600 font-bold">*</span>
                  </label>
                  <div className="mt-2">
                    <TextArea
                      id="address"
                      name="address"
                      type="text"
                      defaultValue={userAddress}
                      onChange={(e) => setUserAddress(e.target.value)}
                      disabled
                      className="block w-full rounded-md border-0 text-gray-900 px-2 font-medium shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="grid grid-cols-2 max-[640px]:grid-cols-1 mt-4">
                  <div className="mx-12 max-[640px]:my-4">
                    <Link to="/technohub/learner/allprogram">
                      <button className="flex w-full justify-center rounded-md bg-red-400 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                        Back Home
                      </button>
                    </Link>
                  </div>
                  <div className="mx-12">
                    <button
                      type="submit"
                      className={`bg-gradient-to-r from-blue-400 to-blue-700 
               hover:from-blue-400 hover:to-green-400  w-full rounded-md  px-2 py-2 text-center text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2  ${
                 isLoading ? "cursor-not-allowed" : ""
               }`}
                    >
                      {isLoading ? "Loading..." : "Submit Now"}
                    </button>
                  </div>
                </div>
              </form>
              <div className="text-red-500 text-sm italic text-center pt-5">
                {subError === "fields" && (
                  <strong className="text-red-500 text-sm italic text-center">
                    *Please Fill All The Mandatory Fields Appropriately.*
                  </strong>
                )}
                {networkError && (
                  <strong className="text-red-500 text-sm italic text-center">
                    *{networkError}*
                  </strong>
                )}
                {isLoading && (
                  <strong className="text-blue-800 text-sm text-center">
                    Your Sponsorship Application Is Being Submited...
                  </strong>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default SponsorshipForm;

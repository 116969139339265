import React, { useState, useEffect, useCallback } from "react";
import { fetch } from "../../service/utils";
import { theme } from "antd";
import { BeatLoader } from "react-spinners";
import useRazorpay from "react-razorpay";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import images from "../../img/Technohub.jpeg";
import { RAZORPAY_TEST_KEY } from "../../service/constants";

const EnablerSponsor = () => {
  const Razorpay = useRazorpay();
  const [isLoading, setIsLoading] = useState(true);
  const [networkError, setNetworkError] = useState("");
  const [applyLoading, setApplyLoading] = useState(false);

  const [allSponsors, setAllSponsors] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userdata = useSelector((state) => state.user.userData);

  const getAllSponsors = async () => {
    try {
      const token = localStorage.getItem("lgs_token");
      const headers = { Authorization: `Bearer ${token}` };
      const response = await fetch(
        "/learner/get-sponsor",
        "get",
        null,
        headers
      );
      setIsLoading(false);
      if (response.ok === false) {
        setIsLoading(false);
        setNetworkError(response.data.message);
      }
      setAllSponsors(response.data.getSponsors);
    } catch (err) {
      setIsLoading(false);
      if (err.response && err.response.data && err.response.data.message) {
        setNetworkError(err.response.data.message);
      } else {
        setNetworkError("Something Went Wrong. Please Try Again Later.");
      }
    } finally {
    }
  };
  useEffect(() => {
    getAllSponsors();
  }, []);

  const [checkState, setCheckState] = useState([]);
  const [selectLeaners, setSelectLeaners] = useState([]);

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setCheckState((prevCheckState) => [...prevCheckState, Number(value)]);
    } else {
      setCheckState((prevCheckState) =>
        prevCheckState.filter((item) => item != value)
      );
    }
  };

  const selectedSponsor = selectLeaners.filter((learner) =>
    checkState.includes(learner.learner_id)
  );
  const handleSponsordata = (learnerData) => {
    if (
      checkState &&
      !selectLeaners.some(
        (learner) => learner.learner_id === learnerData.learner_id
      )
    ) {
      setSelectLeaners((prevCheckState) => [...prevCheckState, learnerData]);
    }
  };
  // *checkbox end

  const getTotalFee = () => {
    let fee = 0;
    allSponsors.map((benef) => {
      let selectedBenef = checkState.find((id) => id === benef.user_id);
      if (selectedBenef) {
        fee += benef.program_fee;
      }
    });
    return fee;
  };

  const sponsorHandler = async () => {
    setApplyLoading(true);
    try {
      const token = localStorage.getItem("lgs_token");
      const headers = { Authorization: `Bearer ${token}` };
      const res = await fetch(
        "/enabler/pay-sponsor",
        "post",
        {
          pay_amount: getTotalFee(),
          learners: selectedSponsor,
        },
        headers
      );
      if (res?.data?.ok === true) {
        setApplyLoading(false);

        displayRazorpay(res.data.order, res.data.pay_id, selectedSponsor);
      }
      if (res.data.ok === false) {
        setApplyLoading(false);
        setNetworkError(res.data.message);
      }
    } catch (err) {
      setApplyLoading(false);
    }
  };
  const displayRazorpay = useCallback((order, pay_id, selectedSponsor) => {
    try {
      const options = {
        key: RAZORPAY_TEST_KEY, // Enter the Key ID generated from the Dashboard
        // amount: getTotalFee() * 100,
        amount: order.amount,
        currency: order.currency,
        name: "Lavanya Gurukul",
        description: "Test Transaction",
        image: `${images}`,
        order_id: order.id,
        handler: async (response) => {
          const formData = new FormData();
          formData.set("razorpayPaymentId", response.razorpay_payment_id);
          formData.set("razorpayOrderId", response.razorpay_order_id);
          formData.set("razorpaySignature", response.razorpay_signature);

          const token = localStorage.getItem("lgs_token");
          const headers = { Authorization: `Bearer ${token}` };

          try {
            const res = await fetch(
              "/enabler/sponsor-payment-handler",
              "post",
              {
                razorpayPaymentId: response.razorpay_payment_id,
                razorpayOrderId: response.razorpay_order_id,
                razorpaySignature: response.razorpay_signature,
                pay_id: pay_id,
                learners: selectedSponsor,
              },
              headers
            );
            getAllSponsors();
            setSelectLeaners([]);
          } catch (err) {
            console.log(err);
          }
          navigate("/technohub/enabler/payment-status");
          //  toast.success("Successfully Purchased Program!");
        },
        prefill: {
          name: userdata.contact_person_name,
          email: userdata.contact_person_email,
          contact: userdata.contact_person_mobile,
        },
        notes: {
          address: "Lavanya Gurukul",
        },
        theme: {
          color: "#021c63",
        },
      };
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      console.log(error);
    }
  }, []);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  // const totalamountToPay = selectBox.reduce((ttl, amount) => ttl + amount, 0);

  return (
    <>
      <div className="text-center font-bold text-2xl m-6">
        <span className="text-center ">Sponsorship Applicants</span>
      </div>
      <div
        style={{
          padding: 6,
          minHeight: 360,
          background: colorBgContainer,
        }}
      >
        {isLoading && (
          <div className="flex justify-center h-screen items-center">
            <BeatLoader color="#34b69c" loading={true} size={15} />
          </div>
        )}

        {networkError && isLoading === false && (
          <div className="text-center mt-8">
            <strong className="font-bold text-red-600">{networkError}</strong>
          </div>
        )}

        {!isLoading && !networkError && (
          <>
            <div className="flex flex-col mx-4   border-1 text-center max-[768px]:overflow-x-auto">
              <div className="sm:-mx-4 lg:-mx-2">
                <div className="inline-block min-w-[100%]  sm:px-6 lg:px-4">
                  <div className="overflow-hidden ">
                    <table className="mx-10 max-[768px]:mx-2 md-w-full lg-w-full sm-w-full text-left text-sm font-light ">
                      <thead className="  border-b  font-medium bg-neutral-200">
                        <tr className="py-4 w-[100%]">
                          <th scope="col" className=" px-2 py-2 w-[3%]">
                            #
                          </th>
                          <th scope="col" className="px-2 py-2 w-[12%]">
                            Name
                          </th>
                          <th scope="col" className="px-2 py-2 w-[9%]">
                            Contact
                          </th>{" "}
                          <th scope="col" className="px-2 py-2 w-[12%]">
                            Father's occupation
                          </th>
                          <th scope="col" className="px-2 py-2 w-[12%]">
                            Family Income
                          </th>
                          <th scope="col" className="px-2 py-2 w-[22%]">
                            Address
                          </th>
                          <th scope="col" className="px-2 py-2 w-[12%]">
                            Program Name
                          </th>
                          <th scope="col" className="px-2 py-2 w-[12%]">
                            Program Fee
                          </th>
                          <th scope="col" className="px-2 py-2 w-[5%]">
                            Select
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {allSponsors &&
                          allSponsors.map((sponsor, ind) => {
                            let learnerData = "";
                            return (
                              <tr
                                key={sponsor}
                                className="border-b bg-neutral-100 transition duration-300 ease-in-out hover:bg-neutral-200 hover:text-black"
                              >
                                <td className="whitespace-nowrap px-2 py-4 font-medium">
                                  {ind + 1}
                                </td>
                                <td className="whitespace-nowrap px-2 py-4">
                                  {sponsor.learner_name}
                                </td>
                                <td className="whitespace-nowrap px-2 py-4">
                                  {sponsor.learner_contact}
                                </td>
                                <td className="whitespace-nowrap px-2 py-4">
                                  {sponsor.father_occupation}
                                </td>
                                <td className="whitespace-nowrap px-2 py-4">
                                  {sponsor.family_income}
                                </td>
                                <td className="whitespace-nowrap px-2 py-4">
                                  {sponsor.learner_address}
                                </td>
                                <td className="whitespace-nowrap px-2 py-4">
                                  {sponsor.program_name}
                                </td>
                                <td className="whitespace-nowrap px-2 py-4 font-medium">
                                  &#8377; {sponsor.program_fee}
                                </td>
                                <td className="whitespace-nowrap px-2 py-4">
                                  <input
                                    type="checkbox"
                                    className="w-5 h-5"
                                    value={sponsor.user_id}
                                    onChange={handleCheckboxChange}
                                    onClick={() =>
                                      handleSponsordata(
                                        (learnerData = {
                                          program_fee: sponsor.program_fee,
                                          program_id: sponsor.program_id,
                                          learner_id: sponsor.user_id,
                                          enabler_id: userdata.user_id,
                                        })
                                      )
                                    }
                                    checked={checkState.includes(
                                      Number(sponsor.user_id)
                                    )}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              {checkState.length > 0 === true && (
                <h2 className="mt-4 font-bold text-[20px] ">
                  Total Fee: &#8377; {getTotalFee()}
                </h2>
              )}
            </div>
            <div className="mx-4 my-6 flex flex-col items-center justify-center  text-center ">
              <button
                // onClick={() => sponsorHandler()}
                disabled={checkState.length == 0}
                className={`${
                  applyLoading
                    ? "cursor-not-allowed bg-green-300 w-48 max-[640px]:w-24 sm:w-40 md:w-48 lg:w-52 rounded-md px-2 py-[10px] text-center   text-white max-[640px]:text-lg text-xl font-semibold"
                    : `w-48 max-[640px]:w-24 sm:w-40 md:w-48 lg:w-52 rounded-md px-2 py-[10px] text-center   text-white max-[640px]:text-lg text-xl font-semibold ${
                        checkState.length == 0
                          ? "cursor-not-allowed bg-green-300"
                          : "hover:bg-green-600 bg-green-400"
                      }`
                }`}
              >
                {applyLoading ? (
                  <div className="flex justify-center items-center">
                    <BeatLoader color="#042009" loading={true} size={12} />
                  </div>
                ) : (
                  "Sponsor"
                )}
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default EnablerSponsor;

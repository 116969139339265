import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    isAuth: false,
    userData: {},
  },
  reducers: {
    setUser(state, action) {
      state.isAuth = true;
      state.userData = action.payload;
    },
    clearUser(state) {
      state.isAuth = false;
      state.userData = {};
    },
    setParticipantImage(state, action) {
      state.isAuth = true;
      state.userData.participant_photo = action.payload;
    },
  },
});

export const { setUser, clearUser, setParticipantImage } = userSlice.actions;
export default userSlice.reducer;

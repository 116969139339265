import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import LearnerLoginMiddleware from "../middleware/learner-login-middleware";
import LearnerTokenMiddleware from "../middleware/learner-token-middleware";
import SelectCourseMiddleware from "../middleware/select-course-middleware";
import ProfileMiddleware from "../middleware/profile-middleware";
import SponsorshipForm from "../component/learner/SponsorshipForm";
import AllPrograms from "../component/learner/AllPrograms";

const LearnerProgram = lazy(() =>
  import("../component/learner/LearnerProgram")
);
const ChangePwd = lazy(() => import("../component/learner/ChangePwd"));

const LearnerProfile = lazy(() =>
  import("../component/learner/LearnerProfile")
);
const LoginAndSignUpComponent = lazy(() =>
  import("../component/learner/LoginAndSignUpComponent")
);
const LearnerCheckout = lazy(() =>
  import("../component/learner/LearnerCheckout")
);

const MyPrograms = lazy(() => import("../component/learner/MyPrograms"));

const Learner = () => {
  return (
    <Routes>
      <Route
        path="allprogram"
        element={
          <SelectCourseMiddleware>
            <LearnerProgram />
          </SelectCourseMiddleware>
        }
      />
      <Route
        path="all-programs"
        element={
          <LearnerTokenMiddleware>
            <AllPrograms />
          </LearnerTokenMiddleware>
        }
      />
      <Route
        path="my-programs"
        element={
          <LearnerTokenMiddleware>
            <MyPrograms />
          </LearnerTokenMiddleware>
        }
      />

      <Route
        path="changepwd"
        element={
          <LearnerTokenMiddleware>
            <ChangePwd />
          </LearnerTokenMiddleware>
        }
      />

      <Route
        path="apply"
        element={
          <LearnerTokenMiddleware>
            <SponsorshipForm />
          </LearnerTokenMiddleware>
        }
      />

      <Route
        path="profile"
        element={
          <ProfileMiddleware>
            <LearnerProfile />
          </ProfileMiddleware>
        }
      />
      <Route
        path="checkout"
        element={
          <LearnerTokenMiddleware>
            <LearnerCheckout />
          </LearnerTokenMiddleware>
        }
      />

      <Route
        path="learner-auth"
        element={
          <LearnerLoginMiddleware>
            <LoginAndSignUpComponent />
          </LearnerLoginMiddleware>
        }
      />
    </Routes>
  );
};

export default Learner;

import React from "react";

import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
function EnablerModal({ openModal, setOpenModal }) {
  const cancelButtonRef = useRef(null);
  return (
    <>
      <Transition.Root show={openModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpenModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className=" px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <Link to="/">
                      <button
                        type="button"
                        className="mt-2 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto max-[640px]:w-[20%] max-[460px]:w-[30%] max-[270px]:w-[40%]"
                        onClick={() => setOpenModal(false)}
                        ref={cancelButtonRef}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-5 h-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </Link>
                  </div>
                  <h3 className="text-center">Enabler </h3>

                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className=" text-center">
                      <div className="grid grid-cols-2 gap-4 mb-6 mt-2">
                        <div>
                          <Link to="/technohub/enabler/login">
                            <button className="bg-blue-500 rounded-md p-2  px-4 font-bold text-yellow-50">
                              Sign In
                            </button>
                          </Link>
                        </div>
                        <div className="">
                          <Link to="/technohub/enabler/signup">
                            <button className="bg-blue-500 rounded-md p-2  px-4 font-bold text-yellow-50">
                              Sign up
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

export default EnablerModal;

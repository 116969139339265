import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";

import TokenMiddleware from "../middleware/middleware";
import LoginMiddleware from "../middleware/login-middleware";
import LoginAdmin from "../component/admin-login/LoginAdmin";
const AdminDashboard = lazy(() => import("../component/pages/AdminDashboard"));
const AdminChangePwd = lazy(() =>
  import("../component/admin-login/AdminChangePwd")
);

const Admin = () => {
  return (
    <Routes>
      <Route
        path="login"
        element={
          <LoginMiddleware>
            <LoginAdmin />{" "}
          </LoginMiddleware>
        }
      />
      <Route
        path="changepwd"
        element={
          <TokenMiddleware>
            <AdminChangePwd />
          </TokenMiddleware>
        }
      />
      <Route
        path="dashboard/*"
        element={
          <TokenMiddleware>
            <AdminDashboard />
          </TokenMiddleware>
        }
      />
    </Routes>
  );
};

export default Admin;

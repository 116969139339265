import { createSlice } from "@reduxjs/toolkit";

const enablerSlice = createSlice({
  name: "programs",
  initialState: {
    allEnablers: [],
  },
  reducers: {
    setAllEnablers(state, action) {
      state.allEnablers = action.payload;
    },

    updateEnabler(state, action) {
      state.allEnablers = state.allEnablers.map((st) =>
        st.user_id == action.payload.user_id ? action.payload : st
      );
    },

    deleteOneEnabler(state, action) {
      state.allEnablers = state.allEnablers.filter(
        (st) => st.user_id != action.payload
      );
    },
  },
});

export const { setAllEnablers, updateEnabler, deleteOneEnabler } =
  enablerSlice.actions;
export default enablerSlice.reducer;

import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { formatArray, getQualificationInfo } from "../../service/utils";

export default function ResponseParticipantModal({
  participantOpen,
  setParticipantOpen,
  participantInfo,
}) {
  const cancelButtonRef = useRef(null);
  return (
    <Transition.Root show={participantOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setParticipantOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 max-[640px]:w-[80%] sm:w-[80%] md:w-[90%] lg:w-[90%]  ">
                <div className="overflow-x-auto sm:-mx-4 lg:-mx-12 mt-4 ">
                  <h1 className="text-center font-bold">Candidates</h1>

                  <table className="mt-4  max-[640px]:ml-2 sm:ml-6 md:mx-8 lg:mx-16 lg:w-[90%] md:w-[80%] max-[640px]:w-[80%] text-center text-md font-light  shadow-2xl ">
                    <thead className="   bg-neutral-200">
                      <tr className="">
                        <th scope="col" className="px-2 py-2">
                          #
                        </th>
                        <th scope="col" className=" px-2 py-2">
                          Name
                        </th>
                        <th scope="col" className="px-2 py-2">
                          Email
                        </th>
                        <th scope="col" className=" px-2 py-2">
                          Contact
                        </th>
                        <th scope="col" className="px-2 py-2">
                          Course
                        </th>
                        <th scope="col" className=" px-2 py-2">
                          Qualification
                        </th>
                        <th scope="col" className="px-2 py-2">
                          Gender
                        </th>
                        <th scope="col" className=" px-2 py-2">
                          Address
                        </th>
                        <th scope="col" className="px-2 py-2">
                          Achievements
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {participantInfo && participantInfo.map((pInfo,ind) => {
                        return (
                          <tr className="border-b transition duration-300 ease-in-out hover:bg-neutral-100 ">
                            <td className="whitespace-nowrap px-6 py-4">
                              {ind + 1}
                            </td>
                            <td className="whitespace-nowrap px-6 py-4">
                              {pInfo.user_name}
                            </td>
                            <td className="whitespace-nowrap px-6 py-4">
                              {pInfo.user_email}
                            </td>
                            <td className="whitespace-nowrap px-6 py-4">
                              {pInfo.user_mobile_no}
                            </td>
                            <td className="whitespace-nowrap px-6 py-4">
                              {pInfo.program_name}
                            </td>
                            <td className="whitespace-nowrap px-6 py-4">
                              {getQualificationInfo(pInfo)}
                            </td>
                            <td className="whitespace-nowrap px-6 py-4">
                              {pInfo.participant_gender}
                            </td>
                            <td className="whitespace-nowrap px-6 py-4">
                              {pInfo.user_address}
                            </td>
                            <td className="whitespace-nowrap px-6 py-4">
                              {pInfo.participant_achievement &&
                                formatArray(pInfo.participant_achievement)}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="text-center"></div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setParticipantOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

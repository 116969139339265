import React, { useEffect, useState } from 'react'
import { BeatLoader } from 'react-spinners';
import { fetch } from "../../service/utils";

const OfferedPrograms = () => {

    
  const [allProgram, setAllProgram] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [networkError, setNetworkError] = useState("");

  // *get all programs start
  const getAllUserProgram = async (deptt_id) => {
    try {
      const token = localStorage.getItem("lgs_token");
      const headers = { Authorization: `Bearer ${token}` };
      const res = await fetch(
        "/program/user-program",
        "post",
        {
          deptt_id,
        },
        headers
      );
      setIsLoading(false);
      if (res.data.ok === false) {
        setIsLoading(false);
        setNetworkError(res.data.message);
      }
      setAllProgram(res.data.allUserPrograms);
      setNetworkError("");
    } catch (err) {
      setIsLoading(false);
      if (err.response && err.response.data && err.response.data.message) {
        setNetworkError(err.response.data.message);
      } else {
        setNetworkError("Something Went Wrong. Please Try Again Later.");
      }
    }
  };
  useEffect(() => {
    const deptt_id = localStorage.getItem("deptt_id");
    getAllUserProgram(deptt_id);
  }, []);
  // *get all programs end
  return (
    <>
      <div className="mx-auto max-w-3xl max-[662px]:text-center md:text-center lg:text-center">
        <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:text-center">
          Offered Programs
        </h2>
        <p className="mt-6 text-lg leading-8 text-gray-700">
          Web Development course covers various topics under Web Development
          such as Database Management, Web Publishing, Web Design, and Web
          Programming. Web Development Courses can be of various types, namely
          Full Stack Web Development, Front End Web Development, Back End Web
          Development, Web Application, etc.
        </p>
      </div>

      {isLoading === true && (
        <>
          <div className="flex justify-center items-center mt-40 mb-44">
            <BeatLoader color="#34b69c" loading={true} size={15} />
            <br />
          </div>
        </>
      )}

      {networkError && isLoading === false && (
        <h1>
          <div className="text-center mt-4">
            <strong className="text-red-600">{networkError}</strong>
          </div>
        </h1>
      )}

      {!isLoading && !networkError && (
        <div className="bg-white py-10 sm:py-12">
          <div className="mx-auto max-w-7xl px-6 lg:px-8 sm:text-center">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-4">
              {allProgram.length > 0 &&
                allProgram.map((progm) => {
                  return (
                    <div
                      key={progm.program_id}
                      className="rounded-2xl hover:scale-105 hover:shadow-lg transition ease-in-out bg-gray-100 py-10 text-center ring-1 ring-inset ring-gray-900/5"
                    >
                      <div className="mx-auto max-w-xs">
                        <p className="mt-4 flex items-baseline justify-center gap-x-2 overflow-hidden">
                          <span className="text-2xl font-bold tracking-tight text-gray-900">
                            {progm.program_name}
                          </span>
                        </p>
                        <p className="text-base font-semibold text-gray-600">
                          {progm.program_desc}
                        </p>
                        <p className="text-base font-semibold text-gray-600">
                          {progm.program_duration} Days
                        </p>
                        <p className="mt-6 flex items-baseline justify-center gap-x-2">
                          <span className="text-5xl font-bold tracking-tight text-gray-900">
                            &#8377; {progm.program_fee}/-
                          </span>
                        </p>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default OfferedPrograms
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { useDispatch } from "react-redux";
import { deleteOneProgram } from "../../redux/features/programSlice";
import { deleteOneEnabler } from "../../redux/features/enablerSlice";
import { deleteOneLearner } from "../../redux/features/learnerSlice";
import { fetch } from "../../service/utils";
import toast from "react-hot-toast";

export default function DeleteProgModal({
  openDelete,
  setOpenDelete,
  id,
  url,
  getData
}) {
  const cancelButtonRef = useRef(null);
  const dispatch = useDispatch();
  const [deleteNetworkError, setDeleteNetworkError] = useState("");
const [deleteLoading,setDeleteLoading] = useState(false)
 
  const deleteProgram = async () => {
    try {
      setDeleteLoading(true);
      setDeleteNetworkError("");
      const token = localStorage.getItem("lgs_token");
      const headers = { Authorization: `Bearer ${token}` };
      const response = await fetch(`${url}/${id}`, "patch", null, headers);
      if (response.data.ok === false) {
        setDeleteNetworkError(response.data.message);
      }
      setDeleteLoading(false);
      if (url === "/program/delete") {
        setOpenDelete(false);
        toast.success("Entry Deleted Successfully!");
        dispatch(deleteOneProgram(id));
      } else if (url === "/enabler/delete") {
        setOpenDelete(false);
        toast.success("Entry Deleted Successfully!");
        dispatch(deleteOneEnabler(id));
      } else {
        setOpenDelete(false);
        toast.success("Entry Deleted Successfully!");
        dispatch(deleteOneLearner(id));
      }
        getData();
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        setDeleteNetworkError(err.response.data.message);
      } else {
        setDeleteNetworkError("Something Went Wrong. Please Try Again Later.");
      }
    } finally {
    }
  };
  const deleteHandler = () => {
    deleteProgram();
  };

  return (
    <Transition.Root show={openDelete} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpenDelete}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Delete Entry?
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Are you sure you want to delete the entry? All of your
                          data will be permanently removed. This action cannot
                          be undone.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-4 sm:flex sm:flex-row-reverse sm:px-6 ">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                    onClick={() => deleteHandler()}
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setOpenDelete(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                  
                </div>
                 
                  <div className=" text-sm text-center my-2">
                    {deleteLoading && (
                      <strong className="text-blue-800 text-sm text-center">
                        Deleting Your Entry...
                      </strong>
                    )}
                    {deleteNetworkError && (
                      <strong className="text-red-600 text-sm text-center">
                        **{deleteNetworkError}**
                      </strong>
                    )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

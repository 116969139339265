import React, { useState, useEffect } from "react";
import { fetch } from "../../service/utils";
import { BeatLoader } from "react-spinners";
import { theme } from "antd";

function LearnerAvailable(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [networkError, setNetworkError] = useState("");
  const [available, setAvailable] = useState([]);
  // *get available candidates start
  const getCandidates = async () => {
    setNetworkError("");
    try {
      const token = localStorage.getItem("lgs_token");
      const headers = { Authorization: `Bearer ${token}` };
      const response = await fetch(
        "/enabler/available-candidates",
        "get",
        null,
        headers
      );
      if (response.data.ok === false) {
        setIsLoading(false);
        setNetworkError(response.data.message);
      }

      setAvailable(response.data.availableCandidates);
      setIsLoading(false);
      setNetworkError("");
    } catch (err) {
      setIsLoading(false);
      if (err.response && err.response.data && err.response.data.message) {
        setNetworkError(err.response.data.message);
      } else {
        setNetworkError("Something Went Wrong. Please Try Again Later.");
      }
    } finally {
    }
  };
  useEffect(() => {
    getCandidates();
  }, []);
  // *get available candidates end

  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <>
      <div className="text-center font-semibold text-lg m-1">
        <span className="text-center ">Available Candidates</span>
      </div>
      <div
        style={{
          padding: 6,
          minHeight: 360,
          background: colorBgContainer,
        }}
      >
        {isLoading && (
          <div className="flex justify-center h-screen items-center">
            <BeatLoader color="#34b69c" loading={true} size={15} />
          </div>
        )}

        {networkError && isLoading === false && (
          <div className="text-center mt-8">
            <strong className="font-bold text-red-600">{networkError}</strong>
          </div>
        )}

        {!isLoading && !networkError && (
          <div className="flex flex-col border-1 mt-4">
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-4">
              <div className="inline-block min-w-[100%] sm:px-6 lg:px-4">
                <div className="overflow-hidden">
                  <table className="mt-4 rounded-xl max-[640px]:mx-2 sm:mx-6 md:mx-6 lg:mx-8 lg:w-[95%] md:w-[80%] max-[640px]:w-[80%] text-center text-md font-light shadow-xl ">
                    <thead className="rounded-xl bg-neutral-200">
                      <tr className="">
                        <th scope="col" className="px-2 py-2">
                          #
                        </th>
                        <th scope="col" className=" px-2 py-2">
                          Name
                        </th>
                        <th scope="col" className="px-2 py-2">
                          Email
                        </th>
                        <th scope="col" className=" px-2 py-2">
                          Contact
                        </th>
                        <th scope="col" className="px-2 py-2">
                          Course
                        </th>
                        <th scope="col" className=" px-2 py-2">
                          Qualification
                        </th>
                        <th scope="col" className="px-2 py-2">
                          Gender
                        </th>
                        <th scope="col" className=" px-2 py-2">
                          Address
                        </th>
                        <th scope="col" className="px-2 py-2">
                          Achievements
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {available &&
                        available.map((learner, ind) => {
                          return (
                            <tr
                              key={learner}
                              className="border-b bg-neutral-100 transition duration-300 ease-in-out hover:bg-neutral-200 hover:text-black"
                            >
                              <td className="whitespace-nowrap px-6 py-2 font-medium">
                                {ind + 1}
                              </td>
                              <td className="whitespace-nowrap px-4 py-2">
                                {learner.user_name}
                              </td>
                              <td className="whitespace-nowrap px-4 py-2">
                                {learner.user_email}
                              </td>
                              <td className="whitespace-nowrap px-4 py-2">
                                {learner.user_mobile_no}
                              </td>
                              <td className="whitespace-nowrap px-4 py-2">
                                {learner.program_name}
                              </td>
                              <td className="whitespace-nowrap px-4 py-2">
                                {learner.participant_qualification &&
                                  learner.participant_qualification
                                    .slice(0, 1)
                                    .map((edu) => {
                                      return <span>{edu.degree}</span>;
                                    })}
                              </td>
                              <td className="whitespace-nowrap px-4 py-2">
                                {learner.participant_gender}
                              </td>
                              <td className="whitespace-nowrap px-4 py-2">
                                {learner.user_address}
                              </td>{" "}
                              <td className="whitespace-nowrap px-4 py-2">
                                {learner.participant_achievement &&
                                  learner.participant_achievement.map(
                                    (achiev) => {
                                      return <span>{achiev}</span>;
                                    }
                                  )}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default LearnerAvailable;

import React, { useState, useEffect } from "react";
import { emailreg } from "../../validations/validation";
import { Link, useNavigate } from "react-router-dom";
import { useInput } from "../../hook/input-hook";
import { fetch } from "../../service/utils";
import { setUser } from "../../redux/features/userSlice";
import { useDispatch } from "react-redux";
import images from "../../service/imageImporter";
import toast from "react-hot-toast";
import EyeIcon from "../eye-icon/EyeIcon";

const isEmail = (value) => {
  if (value.trim() === "") {
    return { validated: false, message: "**Please Enter Your Email**" };
  } else if (!emailreg.test(value)) {
    return { validated: false, message: "**Your Email Is Invalid**" };
  } else {
    return { validated: true, message: "" };
  }
};
const isPassword = (value) => {
  if (value.trim() === "") {
    return { validated: false, message: "**Please Enter Your Password**" };
  } else {
    return { validated: true, message: "" };
  }
};

function LoginAdmin(props) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [networkError, setNetworkError] = useState(false);

  const [isEmailTouchblur, setEmailTouchblur] = useState("");
  const [isPasswordTouch, setisPasswordTouch] = useState("");

  const {
    hasError: hasErrorEmail,
    enterValue: enterValueEmail,
    onChangeHandler: onChangeHandlerEmail,
    reset: resetEmail,
    message: emailMessage,
    isTouch: isTouchEmail,
    onBlurHandler: onBlurHandlerEmail,
  } = useInput(isEmail, setNetworkError, setEmailTouchblur);

  const {
    hasError: hasErrorPassword,
    enterValue: enterValuePassword,
    message: passwordMessage,
    onChangeHandler: onChangeHandlerPassword,
    reset: resetPassword,
    isTouch: isTouchPassword,
    onBlurHandler: onBlurHandlerPwd,
  } = useInput(isPassword, setNetworkError, setisPasswordTouch);

  const [subError, setSubError] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    setSubError("");
    setEmailTouchblur("");
    setisPasswordTouch("");
  }, [enterValueEmail, enterValuePassword]);

  const submitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setNetworkError("");

    if (hasErrorPassword === true || hasErrorEmail === true) {
      setSubError("fields");
      setIsLoading(false);
      return false;
    } else if (!isTouchEmail || !isTouchPassword) {
      if (!isTouchEmail) {
        setEmailTouchblur("**Please Enter Your Email**");
      }
      if (!isTouchPassword) {
        setisPasswordTouch("**Please Enter Your Password**");
      }
      setSubError("fields");
      setIsLoading(false);
      return false;
    }

    try {
      const response = await fetch("/auth/admin-login", "post", {
        email: enterValueEmail,
        password: enterValuePassword,
      });
      setIsLoading(false);
      if (response.data) {
        const userData = {
          user_id: response.data.user.user_id,
          role_id: response.data.user.role_id,
          user_name: response.data.user.user_name,
          user_email: response.data.user.user_email,
          user_mobile_no: response.data.user.user_mobile_no,
          user_address: response.data.user.user_address,
        };
        dispatch(setUser(userData));
        localStorage.setItem("lgs_token", response.data.token);
        resetEmail();
        resetPassword();
        navigate("/admin/dashboard/home");
        toast.success("Login Successful!");
      }
    } catch (err) {
      setIsLoading(false);
      if (err.response && err.response.data && err.response.data.message) {
        setNetworkError(err.response.data.message);
        toast.error(err.response.data.message);
      } else {
        setNetworkError("Something Went Wrong. Please Try Again Later.");
        toast.error("Something Went Wrong. Please Try Again Later.");
      }
    } finally {
    }
  };
  const [type, setType] = useState("password");

  const handleToggle = () => {
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-10 w-auto"
            src={images[`Technohub.jpeg`]}
            alt="Your Company"
          />
          <h2 className="mt-4 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign in to Admin Account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={submitHandler}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-bold leading-6 text-gray-900"
              >
                Email
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="text"
                  placeholder="Enter Your Email"
                  autoComplete="email"
                  onChange={onChangeHandlerEmail}
                  value={enterValueEmail}
                  onBlur={() => {
                    onBlurHandlerEmail();
                    setEmailTouchblur("");
                  }}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-3 font-medium"
                />
              </div>
              {hasErrorEmail && (
                <strong className="text-red-500 text-sm italic">
                  {emailMessage}
                </strong>
              )}
              {isEmailTouchblur && (
                <strong className="text-red-500 text-sm italic">
                  {isEmailTouchblur}
                </strong>
              )}
              {subError === "Email not found" && (
                <strong className="text-red-500 text-sm italic">
                  **This Email Doesn't Exist! You Might Want To SIGN UP.**
                </strong>
              )}
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-bold leading-6 text-gray-900"
                >
                  Password
                </label>
              </div>
              <div className="mt-2 flex">
                <input
                  id="password"
                  name="password"
                  placeholder="Enter Your Password"
                  type={type}
                  autoComplete="current-password"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-3 font-medium"
                  onChange={onChangeHandlerPassword}
                  value={enterValuePassword}
                  onBlur={() => {
                    onBlurHandlerPwd();
                    setisPasswordTouch("");
                  }}
                />
                <span
                  className="flex justify-around items-center cursor-pointer "
                  onClick={handleToggle}
                >
                  <EyeIcon type={type} />
                </span>
              </div>
              {hasErrorPassword && (
                <strong className="text-red-500 text-sm italic">
                  {passwordMessage}
                </strong>
              )}
              {isPasswordTouch && (
                <strong className="text-red-500 text-sm italic">
                  {isPasswordTouch}
                </strong>
              )}
            </div>

            <div className="grid grid-cols-2 max-[640px]:grid-cols-1">
              <div className="mx-4 max-[640px]:my-4">
                <Link to="/">
                  <button className="flex w-full justify-center rounded-md bg-red-400 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    Back Home
                  </button>
                </Link>
              </div>
              <div className="mx-4">
                <button
                  type="submit"
                  disabled={isLoading}
                  className={`bg-gradient-to-r from-blue-400 to-blue-700 
               hover:from-blue-400 hover:to-green-400  w-full rounded-md  px-2 py-2 text-center text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2  ${
                 isLoading ? "cursor-not-allowed" : ""
               }`}
                >
                  {isLoading ? "Loading..." : "Sign In"}
                </button>
              </div>
            </div>
          </form>
          <div className="text-red-500 text-sm  text-center pt-5">
            {subError === "fields" && (
              <strong className="text-red-500 text-sm italic text-center">
                **Please Fill All TheFields Appropriately.**
              </strong>
            )}
            {networkError && (
              <strong className="text-red-500 text-sm  text-center">
                {networkError}
              </strong>
            )}
            {isLoading && (
              <strong className="text-blue-800 text-sm text-center">
                Please Wait, Signing In...
              </strong>
            )}
          </div>
          <div className="flex items-center justify-between mt-6">
            {" "}
            <div className="text-sm">
              <Link
                href="#"
                className="font-semibold text-indigo-600 hover:text-indigo-500"
              >
                Forgot password?
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginAdmin;

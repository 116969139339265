import { createSlice } from "@reduxjs/toolkit";

const orderSlice = createSlice({
  name: "orderprogram",
  initialState: {
    applicationId: 0,
    orderApply: {},
  },
  reducers: {
    setOrderApply(state, action) {
      state.applicationId = action.payload.applicationId;
      state.orderApply = action.payload.order;
    },
  },
});

export const { setOrderApply } = orderSlice.actions;
export default orderSlice.reducer;

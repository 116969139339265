import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { clearUser, setUser } from "../redux/features/userSlice";
import { BeatLoader } from "react-spinners";

const HomeMiddleware = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [roleId, setRoleId] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem("lgs_token");
    if (!token) {
      setIsLoading(false);
      setIsTokenValid(false);
      localStorage.removeItem("lgs_token");
      dispatch(clearUser());
    } else {
      fetch("https://api.lavanyagurukul.com/api/auth/token-info", {
      // fetch("http://localhost:5000/api/auth/token-info", {
      //  fetch("https://apilavanyagurukuldev.q4hosting.com/api/auth/token-info", {
         method: "POST",
         headers: {
           "Content-Type": "application/json",
           authorization: `Bearer ${token}`,
         },
       })
         .then((response) => {
           return response.json();
         })
         .then((data) => {
           if (!data.ok) {
             setIsLoading(false);
             setIsTokenValid(false);
             dispatch(clearUser());
             localStorage.removeItem("lgs_token");
           } else {
             setIsLoading(false);
             const { tokenData } = data;
             const userInfo = tokenData;
             setRoleId(userInfo.role_id);
             setIsTokenValid(true);
             dispatch(setUser(userInfo));
           }
         })
         .catch((error) => {
           setIsLoading(false);
           setIsTokenValid(false);
           localStorage.removeItem("lgs_token");
           dispatch(clearUser());
         });
    }
  }, []);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center mt-40 mb-44">
        <BeatLoader color="#34b69c" loading={true} size={15} />
        <br />
      </div>
    );
  } else if (!isTokenValid) {
    return <React.Fragment>{children}</React.Fragment>;
  } else if (isTokenValid && roleId == 1) {
    return <Navigate to="/admin/dashboard/home" />;
  } else if (isTokenValid && roleId == 2) {
    return <Navigate to="/technohub/enabler/dashboard" />;
  } else if (isTokenValid && roleId == 3) {
    return <Navigate to="/technohub/learner/allprogram" />;
  }
};

export default HomeMiddleware;

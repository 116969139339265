import { createSlice } from "@reduxjs/toolkit";

const myProgramsSlice = createSlice({
  name: "my-programs",
  initialState: {
    allottedPrograms: [],
  },
  reducers: {
    setAllottedPrograms(state, action) {
      state.allottedPrograms = action.payload;
    },
    clearAllottedPrograms(state) {
      state.allottedPrograms = [];
    },
  },
});

export const { setAllottedPrograms, clearAllottedPrograms } = myProgramsSlice.actions;
export default myProgramsSlice.reducer;

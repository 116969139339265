import { createSlice } from "@reduxjs/toolkit";

const desiredProgramsSlice = createSlice({
  name: "desired_courses",
  initialState: {
    desiredCourses: [],
  },
  reducers: {
    setDesPrograms(state, action) {
      state.desiredCourses = action.payload;
    },
  },
});

export const { setDesPrograms } = desiredProgramsSlice.actions;
export default desiredProgramsSlice.reducer;

import "./App.css";
import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Navbar from "./layout/Navbar";
import TechnoHub from "./component/pages/TechnoHub";
import Admin from "./routes/admin.routes";
import Footer from "./layout/Footer";
import { useEffect } from "react";
import { fetch } from "./service/utils";
import Enabler from "./routes/enabler.routes";
import Learner from "./routes/learner.routes";
import { useDispatch, useSelector } from "react-redux";
import { setDepartmentDetails } from "./redux/features/departmentSlice";
import {
  setDeptLoading,
  setDeptNetworkError,
} from "./redux/features/deptLoadingSlice";
import HomeMiddleware from "./middleware/home-middleware";
import { BounceLoader } from "react-spinners";
import { Toaster } from "react-hot-toast";
import ErrorPage from "./component/pages/ErrorPage";

const Home = lazy(() => import("./component/Home"));

function App() {
  const dispatch = useDispatch();

  //* departments start
  const getDepartments = async () => {
    try {
      dispatch(setDeptNetworkError(""));
      dispatch(setDeptLoading(true));
      const token = localStorage.getItem("lgs_token");
      const headers = { Authorization: `Bearer ${token}` };
      const response = await fetch("/department/get", "get", null, headers);
      dispatch(setDeptLoading(false));
      if (response.data.ok === false) {
        dispatch(setDeptLoading(false));
        dispatch(setDeptNetworkError(response.data.message));
      }
      dispatch(setDepartmentDetails(response.data.allDepartments));
    } catch (err) {
      dispatch(setDeptLoading(false));
      if (err.response && err.response.data && err.response.data.message) {
        dispatch(setDeptNetworkError(err.response.data.message));
      } else {
        dispatch(
          setDeptNetworkError("Something Went Wrong. Please Try Again Later.")
        );
      }
    } finally {
    }
  };
  useEffect(() => {
    getDepartments();
  }, []);
  //* departments end

  const loading = (
    <div className="flex justify-center h-screen items-center">
      <BounceLoader color="#34b69c" loading={true} size={60} />
    </div>
  );

  const userData = useSelector((state) => state.user.userData);

  return (
    <>
      <Navbar />
      <Suspense fallback={loading}>
        <div>
          <Toaster position="top-right" />
        </div>
        <Routes>
          <Route
            path="/"
            element={
              <HomeMiddleware>
                <Home />
              </HomeMiddleware>
            }
          />
          <Route path="/admin/*" element={<Admin />} />
          <Route path="/technohub" element={<TechnoHub />} />
          <Route path="/technohub/enabler/*" element={<Enabler />} />
          <Route path="/technohub/learner/*" element={<Learner />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </Suspense>
      {/* {isAuth ? null : <Footer />} */}
      {/* <Footer /> */}
      {userData.role_id == 1 ? null : userData.role_id == 2 ? (
        <>
          <Footer />
        </>
      ) : userData.role_id == 3 ? (
        <>
          <Footer />
        </>
      ) : (
        <>
          <Footer />
        </>
      )}
    </>
  );
}

export default App;


const images = {};
const context = require.context("../img", false, /\.(png|jpg|jpeg|gif|svg)$/);

context.keys().forEach((key) => {
  const imageName = key.split("/").pop();
  images[imageName] = context(key);
});


export default images;

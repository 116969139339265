import { createSlice } from "@reduxjs/toolkit";

const enablerJobSlice = createSlice({
  name: "PostedJob",
  initialState: {
    allPostedJob: [],
  },
  reducers: {
    setallPostedJob(state, action) {
      state.allPostedJob = action.payload;
    },

    updatePostedJob(state, action) {
      state.allPostedJob = state.allPostedJob.map((st) =>
        st.user_id == action.payload.user_id ? action.payload : st
      );
    },

    deleteOnePostedJob(state, action) {
      state.allPostedJob = state.allPostedJob.filter(
        (st) => st.user_id != action.payload
      );
    },
  },
});

export const { setallPostedJob, updatePostedJob, deleteOnePostedJob } =
  enablerJobSlice.actions;
export default enablerJobSlice.reducer;

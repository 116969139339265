import { createSlice } from "@reduxjs/toolkit";

const programSlice = createSlice({
  name: "programs",
  initialState: {
    programs: [],
    oneProgram: {
      program_name: "",
      program_desc: "",
      program_duration: "",
      program_fee: "",
    },
  },
  reducers: {
    setPrograms(state, action) {
      state.programs = action.payload;
    },
    setOneProgram(state, action) {
      state.oneProgram = action.payload;
    },
    clearOneProgram(state) {
      state.oneProgram = {
        program_name: "",
        program_desc: "",
        program_duration: "",
        program_fee: "",
      };
    },
    addProgram(state, action) {
      state.programs = [...state.programs, action.payload];
    },
    updateProgram(state, action) {
      state.programs = state.programs.map((st) =>
        st.program_id == action.payload.program_id ? action.payload : st
      );
    },

    deleteOneProgram(state, action) {
      state.programs = state.programs.filter(
        (st) => st.program_id != action.payload
      );
    },
  },
});

export const {
  setPrograms,
  setOneProgram,
  clearOneProgram,
  addProgram,
  updateProgram,
  deleteOneProgram,
} = programSlice.actions;
export default programSlice.reducer;

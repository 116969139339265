import React from "react";
import { Tabs, Tab } from "./EnablerJobTabs";
import UpdateJob from "./UpdateJob";
import HrPolicy from "./HrPolicy";

function EnablerJobComponent(props) {
  return (
    <div>
      <Tabs>
        <Tab className="" label="Job Definition">
          <UpdateJob />
        </Tab>
        <Tab label="HR Policy">
          <HrPolicy />
        </Tab>
      </Tabs>
    </div>
  );
}

export default EnablerJobComponent;

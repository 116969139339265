import { createSlice } from "@reduxjs/toolkit";

const deptLoadingSlice = createSlice({
  name: "department",
  initialState: {
    isLoading: false,
    networkError: "",
  },
  reducers: {
    setDeptLoading(state, action) {
      state.isLoading = action.payload;
    },
    setDeptNetworkError(state, action) {
      state.isLoading = action.payload;
    },
  },
});

export const { setDeptLoading, setDeptNetworkError } = deptLoadingSlice.actions;
export default deptLoadingSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

const departmentSlice = createSlice({
  name: "department",
  initialState: {
    department: [],
  },
  reducers: {
    setDepartmentDetails(state, action) {
      state.department = action.payload;
    },
  },
});

export const { setDepartmentDetails } = departmentSlice.actions;
export default departmentSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

const learnerSlice = createSlice({
  name: "programs",
  initialState: {
    allLearners: [],
  },
  reducers: {
    setAllLearners(state, action) {
      state.allLearners = action.payload;
    },

    updateLearner(state, action) {
      state.allLearners = state.allLearners.map((st) =>
        st.user_id == action.payload.user_id ? action.payload : st
      );
    },

    deleteOneLearner(state, action) {
      state.allLearners = state.allLearners.filter(
        (st) => st.user_id != action.payload
      );
    },
  },
});

export const { setAllLearners, updateLearner, deleteOneLearner } =
  learnerSlice.actions;
export default learnerSlice.reducer;
